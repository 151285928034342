@import "../../variables.scss";

.Letter {
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/images/letter-background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .swipper--div {
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: #fff;
    height: 80vh;
    width: 90%;

    margin: 0 auto;
    margin-top: 100px;

    border-radius: 30px;
    box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.2);

    .logo {
      width: 70px;
      position: relative;
      left: 39%;
      top: -20px;
    }

    h1 {
      text-align: center;
      font-family: $text--font;
      font-size: .9rem;
      width: 90%;
        margin-left: 15px;
    }

   .sub {
    position: relative;
    top: 30px;
    left: 200px;
    font-family: $text--font;
   }

   .buttons {
    position: relative;
    top: 50px;
    display: flex;
    justify-content: space-evenly;

    .yes, .no {
        width: 100px;
        height: 40px;
        border: none;
        border-radius: 10px;
        color: #fff;
        text-transform: uppercase;
        cursor: pointer;
    }

    .yes {
        background-color: rgb(190, 30, 190);
    }

    .no {
        background-color: rgb(214, 27, 27);
    }
   }
  }
}
